<script>
import ButtonRedirect from "@/components/helpers/ButtonRedirect.vue";

export default {
  name: "LeadershipCouncilBanner",

  components: { ButtonRedirect },

  methods: {
    downloadAttachment(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<template>
  <div class="leadership-council-banner">
    <div class="leadership-council-banner__content">
      <h4>Лидерский и Предлидерский совет</h4>
      <p>
        Познакомьтесь с самыми успешными дистрибьюторами компании RC&nbsp;GROUP
      </p>

      <ButtonRedirect
        :text="'Открыть документ'"
        :arrow-position="'right'"
        :button-style="'bold'"
        @click="
          downloadAttachment('./docs/лидерский и предлидерский совет.pdf')
        "
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.leadership-council-banner {
  width: 100%;
  background-color: $light-primary;
  border-radius: 8px;
  background-image: url("../assets/images/leadershi_council_background.png");
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;

  &__content {
    max-width: calc(100% - 200px);
    padding: 32px;

    > h4 {
      @include body-1-bold;
      color: $dark-primary;
      margin-bottom: 8px;
    }

    > p {
      @include text-2;
      color: $dark-primary;
      margin-bottom: 24px;
    }
  }
}

@media (max-width: 900px) {
  .leadership-council-banner {
    &__content {
      padding: 20px;
      max-width: calc(100% - 152px);
    }
  }
}

@media (max-width: 575px) {
  .leadership-council-banner {
    background-image: none;

    &__content {
      padding: 20px;
      max-width: 100%;
    }
  }
}
</style>
