<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import AccountSelector from "@/components/AccountSelector";
import NewsPreview from "@/components/NewsPreview";
import PollItem from "@/components/poll/PollItem";
import ButtonRedirect from "@/components/helpers/ButtonRedirect";
import TelegramChatVip from "@/components/TelegramChatVip.vue";
import TelegramChatNews from "@/components/TelegramChatNews.vue";
import BalanceBlock from "@/components/balance/BalanceBlock.vue";
import BalanceStatistic from "@/components/balance/BalanceStatistic.vue";
import PublicActivitiesBanner from "@/components/dashboard/PublicActivitiesBanner.vue";
import LeadershipCouncilBanner from "@/components/LeadershipCouncilBanner.vue";
import NewUsersStatistic from "@/components/dashboard/NewUsersStatistic.vue";
import AccountInfo from "@/components/dashboard/AccountInfo.vue";
import RcMarket from "@/components/dashboard/RcMarket.vue";
import Slider from "@/components/dashboard/Slider.vue";

export default {
  name: "Dashboard",

  components: {
    Slider,
    RcMarket,
    LeadershipCouncilBanner,
    PublicActivitiesBanner,
    ButtonRedirect,
    AccountSelector,
    NewsPreview,
    PollItem,
    TelegramChatVip,
    TelegramChatNews,
    BalanceBlock,
    BalanceStatistic,
    NewUsersStatistic,
    AccountInfo,
  },

  data() {
    return {
      locales: [
        {
          id: "RUB",
          name: "RU",
        },
        {
          id: "KZT",
          name: "KZ",
        },
      ],

      locale: null,
      loadingQualification: true,
    };
  },

  watch: {
    locale: {
      deep: true,
      handler() {
        if (this.selectedLocale.id !== this.locale.id) {
          this.setLocale(this.locale);
        }
      },
    },

    selectedBinaryAccount() {
      this.loadingQualification = true;

      this.loadQualification({
        binaryAccount: this.selectedBinaryAccount.id,
      }).finally(() => {
        this.loadingQualification = false;
      });

      this.loadTariffsOfBinaryAccount(this.selectedBinaryAccount.id);
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
      selectedLocale: (state) => state.auth.selectedLocale,
      qualification: (state) => state.qualification.qualification,
      tariffsOfBinaryAccount: (state) => state.account.tariffsOfBinaryAccount,
      polls: (state) => state.poll.polls,
      invitedTSPCount: (state) => state.qualification.invitedTSPCount,
    }),

    ...mapGetters({
      getLocaleAccounts: "auth/getLocaleAccounts",
      getUserDistributorAccount: "auth/getUserDistributorAccount",
      getUserAccounts: "auth/getUserAccounts",
      getDistributorNewsByCount: "news/getDistributorNewsByCount",
      getPublicActivitiesNewsByCount: "news/getPublicActivitiesNewsByCount",
    }),

    isShownTelegramVip() {
      return (
        this.user.tariff?.code === "vip_d" ||
        this.user.tariff?.code === "vip_gold_d"
      );
    },

    isPremiumAccount() {
      const tariffCode = this.selectedBinaryAccount?.tariff?.code ?? null;

      if (tariffCode === null) return false;

      return (
        tariffCode === "business_d" ||
        tariffCode === "premium_d" ||
        tariffCode === "vip_d" ||
        tariffCode === "vip_gold_d"
      );
    },
  },

  methods: {
    ...mapMutations({
      setLocale: "auth/SET_LOCALE",
    }),

    ...mapActions({
      loadDistributorNews: "news/loadDistributorNews",
      loadPublicActivitiesNews: "news/loadPublicActivitiesNews",
      loadQualification: "qualification/loadQualification",
      loadTariffsOfBinaryAccount: "account/loadTariffsOfBinaryAccount",
      loadPolls: "poll/loadPolls",
      loadInvitedTSPCount: "qualification/loadInvitedTSPCount",
    }),
  },

  mounted() {
    if (this.selectedBinaryAccount) {
      this.loadQualification({
        binaryAccount: this.selectedBinaryAccount.id,
      }).then(() => {
        this.loadInvitedTSPCount();

        this.loadingQualification = false;
      });

      this.loadTariffsOfBinaryAccount(this.selectedBinaryAccount.id);
    }

    this.loadDistributorNews({ page: 1 });
    this.loadPublicActivitiesNews({ page: 1 });
    this.loadPolls();

    this.locale = this.selectedLocale;
  },
};
</script>

<template>
  <div class="dashboard">
    <Slider name="main_slider" />

    <div class="dashboard__balance balance default-content-padding">
      <div class="balance__wrapper">
        <BalanceBlock :account="getUserDistributorAccount" />

        <BalanceStatistic />
      </div>
    </div>

    <div
      v-if="selectedBinaryAccount || qualification"
      class="dashboard__info info"
    >
      <div v-if="selectedBinaryAccount" class="account-selector-wrapper">
        <AccountSelector />
      </div>

      <div class="info__content default-content-padding">
        <div class="info__content-wrapper">
          <NewUsersStatistic
            v-if="isPremiumAccount"
            class="info__new-users-statistic"
          />

          <RcMarket v-if="isPremiumAccount" />

          <AccountInfo />
        </div>
      </div>
    </div>

    <div class="dashboard__news news default-content-padding">
      <div class="dashboard__subtitle">
        <h4>Последние новости</h4>

        <ButtonRedirect
          text="Все новости"
          arrow-position="right"
          @click="$router.push({ name: 'DistributorNews' })"
        />
      </div>

      <div class="dashboard__telegram">
        <TelegramChatNews />

        <TelegramChatVip v-if="isShownTelegramVip" />
      </div>

      <div v-if="getDistributorNewsByCount(4).length > 0" class="news__list">
        <NewsPreview
          v-for="news in getDistributorNewsByCount(4)"
          :key="news.id"
          :news="news"
        />
      </div>
    </div>

    <div class="dashboard__leadership-council default-content-padding">
      <LeadershipCouncilBanner class="dashboard__leadership-council-banner" />
    </div>

    <div
      class="dashboard__public-activities public-activities default-content-padding"
    >
      <div class="dashboard__subtitle">
        <h4>Общественная деятельность</h4>

        <ButtonRedirect
          text="Все статьи"
          arrow-position="right"
          @click="$router.push({ name: 'PublicActivitiesNews' })"
        />
      </div>

      <div class="dashboard__public-activities-banner">
        <PublicActivitiesBanner />
      </div>

      <div
        v-if="getPublicActivitiesNewsByCount(4).length > 0"
        class="public-activities__list"
      >
        <NewsPreview
          v-for="news in getPublicActivitiesNewsByCount(4)"
          :key="news.id"
          :news="news"
        />
      </div>
    </div>

    <div
      v-if="polls.length > 0"
      class="dashboard__poll default-content-padding"
    >
      <div class="dashboard__subtitle">
        <h4>Опросы</h4>
      </div>

      <PollItem
        v-for="poll in polls"
        :key="poll.id"
        :poll="poll"
        @updatePolls="loadPolls"
      />
    </div>
  </div>
</template>

<style lang="scss">
.dashboard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: $space-xxxl;

  &__subtitle {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: $space-xl;
    max-width: 700px;

    h4 {
      @include text-1;
    }
  }

  &__balance {
    margin-bottom: 40px;
    width: 100%;

    h4 {
      margin-bottom: $space-xl;
      @include text-1;
    }
  }

  .balance {
    &__wrapper {
      display: flex;
      gap: 24px;

      @media (max-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      @media (max-width: 690px) {
        grid-template-columns: 1fr;
      }
    }
  }

  &__info {
    width: 100%;
    margin-bottom: 64px;

    @media (min-width: 426px) and (max-width: 576px) {
      margin-bottom: 48px;
    }
  }

  .info {
    &__content-wrapper {
      max-width: 800px;
    }

    &__content {
      background-color: $background-white;
      padding-top: 64px;
      padding-bottom: 64px;

      @media (max-width: 576px) {
        padding-top: 48px;
        padding-bottom: 48px;
      }
    }

    &__new-users-statistic {
      margin-bottom: 64px;
    }
  }

  &__founder-bonus {
    margin-bottom: $space-xxxl;
    width: 100%;

    .bonus-founder-item {
      background-color: $light-primary;
    }

    @media (min-width: 426px) and (max-width: 576px) {
      margin-bottom: $space-xxl;
    }
  }

  &__news,
  &__public-activities {
    width: 100%;
    margin-bottom: $space-xxxl;

    @media (min-width: 426px) and (max-width: 576px) {
      margin-bottom: $space-xxl;
    }
  }

  .news,
  .public-activities {
    &__list {
      max-width: 694px;
      display: grid;
      grid-template-columns: repeat(auto-fill, 335px);
      grid-gap: $space-l;
      margin-top: 32px;

      @media (min-width: 386px) and (max-width: 789px) {
        grid-template-columns: 1fr;
      }

      @media (max-width: 385px) {
        grid-template-columns: repeat(auto-fill, 100%);
      }
    }
  }

  &__leadership-council {
    margin-bottom: 64px;
    width: 100%;
  }

  &__leadership-council-banner {
    max-width: 694px;
  }

  &__telegram {
    display: grid;
    grid-template-columns: repeat(auto-fill, 335px);
    grid-gap: 24px;
    max-width: 694px;

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
    }
  }

  &__public-activities-banner {
    max-width: 694px;
  }

  &__poll {
    margin-bottom: $space-xxxl;
    max-width: 680px;
    width: 100%;

    @media (min-width: 426px) and (max-width: 576px) {
      margin-bottom: $space-xxl;
    }
  }
}

@media (max-width: 576px) {
  .dashboard {
    .info {
      &__new-users-statistic {
        margin-bottom: 48px;
      }
    }
  }
}

@media (max-width: 425px) {
  .dashboard {
    .info {
      &__new-users-statistic {
        margin-bottom: 32px;
      }
    }
  }
}
</style>
